import { dataPoints } from "../data/dataPoints.json";
import { parse, stringify } from "flatted/esm";
var store = require("store");
const colors = [
  "#4287f5",
  "#ff0000",
  "#00ff1e",
  "#fff200",
  "#28d5b7",
  "#605a04",
  "#a6a4db",
  "#e69c45",
  "#e9b16c",
  "#f826c4",
];
const shadeIncrement = 20;

export const exportConfig = async () => {
  let fileName = Date.now() + "-dataPoints";
  let data = {
    dataPoints: dataPoints,
    customDataSets: store.get("customDataSets"),
  };
  const json = JSON.stringify(data);
  const blob = new Blob([json], { type: "application/json" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
};

export const importConfig = (event) => {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      resolve(JSON.parse(e.target.result));
    };
    fileReader.readAsText(event.target.files[0]);
  });
};

export const prepareDataSets = (fromUploaded) => {
  return new Promise((resolve, reject) => {
    let data = [];
    if (fromUploaded) {
      if (fromUploaded.dataPoints && fromUploaded.customDataSets) {
        data = parseData(fromUploaded.dataPoints);
      } else {
        data = parseData(dataPoints);
      }
    } else {
      let dataFromStore = store.get("dataSets");
      if (dataFromStore) {
        store.set("dataSets", dataFromStore);
        resolve(dataFromStore);
      } else {
        data = parseData(dataPoints);
      }
    }
    resolve(data);
  });
};

const parseData = (dataPoints) => {
  let data = [];
  dataPoints.forEach((dataBlock, index) => {
    let baseColor = colors[index];
    let chartJsDatasets = [];
    dataBlock.items.forEach((dataItem, itemIndex) => {
      let currentChartJsDataset = generateChartJsDataSet(
        dataItem,
        baseColor,
        getShadeValue(dataPoints, itemIndex)
      );
      chartJsDatasets.push({
        chartJsDataSet: currentChartJsDataset,
        checked: true,
      });
    });
    let currentDataBlock = {
      frequency: dataBlock.frequency,
      chartJsDataSets: chartJsDatasets,
      checked: true,
    };
    data.push(currentDataBlock);
  });
  store.set("dataSets", data);
  return data;
};

const getRandomColor = () => {
  return "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
};

const getShadeValue = (dataPoints, itemIndex) => {
  return (
    -(dataPoints.length * (shadeIncrement / 2)) + itemIndex * shadeIncrement
  );
};

const getRandomColors = () => {
  let colors = [];
  for (let numColors = 0; numColors < 50; numColors++) {
    colors[numColors] = getRandomColor();
  }
  return colors;
};

const shadeColor = (color, percent) => {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};

export const generateChartJsDataSet = (dataItem, baseColor, shade) => {
  let color = shadeColor(baseColor, shade);
  let pointStyle = "star";
  let localShade = 50;
  if (dataItem.label.includes("5dBm")) {
    pointStyle = "cross";
    color = shadeColor(colors[0], -localShade);
  }
  if (dataItem.label.includes("5.7dBm")) {
    pointStyle = "triangle";
    color = shadeColor(colors[1], -localShade);
  }
  if (dataItem.label.includes("P_Break")) {
    pointStyle = "circle";
    color = shadeColor(colors[2], -localShade);
  }
  if (dataItem.label.includes("noGT")) {
    color = shadeColor(color, 4 * localShade);
  }
  let chartjsDataSet = {
    label: dataItem.label,
    data: dataItem.points,
    pointStyle: pointStyle,
    radius: 1,
    hoverRadius: 1,
    pointBorderColor: color,
    borderColor: color,
    pointBackgroundColor: color,
    backgroundColor: color,
    fill: false,
    borderWidth: 0.3,
    borderCapStyle: "round",
  };
  return chartjsDataSet;
};

export const fromStringToFloat = (dataSet) => {
  let newDataSet = dataSet;
  newDataSet.points.map((dataPoint) => {
    dataPoint.real = parseFloat(dataPoint.real);
    dataPoint.imag = parseFloat(dataPoint.imag);
  });
  return newDataSet;
};
