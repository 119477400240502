import React, {useState} from "react"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ChromePicker, BlockPicker } from "react-color"
export default ({openColorPicker, handleCloseColorPicker,setColor,color}) => {
    const [selectedColor, setSelectedColor] = useState(color)
    const handleColorChange = (color) => {
        setSelectedColor(color.hex)
        setColor(color.hex)
    }

    return (
        <div>
          <Dialog open={openColorPicker} onClose={() => handleCloseColorPicker()} aria-labelledby="Custom panel">
            <DialogTitle id="Custom Panel">Couleur du dataset</DialogTitle>
            <DialogContent>
              <ChromePicker disableAlpha={true} color={selectedColor} onChange={(color) => handleColorChange(color)} onChangeComplete={(color) => handleColorChange(color)}/>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseColorPicker()} color="primary">
                Ok
              </Button>
              <Button onClick={() => handleCloseColorPicker()} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
}