import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default ({
  data,
  handleCheckFrequency,
  handleCheck,
  gtChecked,
  noGtChecked,
  fiveChecked,
  fivePointChecked,
  pBreakChecked,
}) => {
  return (
    <div className="CheckBoxes">
      <FormGroup row>
        {data.map((dataItem) => {
          return (
            <FormControlLabel
              key={dataItem.frequency}
              control={
                <Checkbox
                  key={dataItem.frequency + "checkbox"}
                  checked={dataItem.checked}
                  onChange={() => handleCheckFrequency({ dataItem })}
                  value={dataItem.frequency}
                />
              }
              label={dataItem.frequency}
            />
          );
        })}
        {
          <React.Fragment>
            <FormControlLabel
              key="GT"
              control={
                <Checkbox
                  key="GTcb"
                  checked={gtChecked}
                  onChange={(event) => handleCheck(event.target)}
                  value="_GT"
                />
              }
              label="GT"
            />
            <FormControlLabel
              key="noGT"
              control={
                <Checkbox
                  key="noGTcb"
                  checked={noGtChecked}
                  onChange={(event) => handleCheck(event.target)}
                  value="_noGT"
                />
              }
              label="noGT"
            />
            <FormControlLabel
              key="5dBm"
              control={
                <Checkbox
                  key="5dBm"
                  checked={fiveChecked}
                  onChange={(event) => handleCheck(event.target)}
                  value="5dBm"
                />
              }
              label="5dBm"
            />
            <FormControlLabel
              key="5.7dBm"
              control={
                <Checkbox
                  key="5.7dBm"
                  checked={fivePointChecked}
                  onChange={(event) => handleCheck(event.target)}
                  value="5.7dBm"
                />
              }
              label="5.7dBm"
            />
            <FormControlLabel
              key="P_Break"
              control={
                <Checkbox
                  key="P_Break"
                  checked={pBreakChecked}
                  onChange={(event) => handleCheck(event.target)}
                  value="P_Break"
                />
              }
              label="P_Break"
            />
          </React.Fragment>
        }
      </FormGroup>
    </div>
  );
};
