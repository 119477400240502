import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ColorPanel from "./ColorPanel";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { fromStringToFloat } from "../utils/utils";
export default ({
  openCustomPanel,
  handleCloseCustomPanel,
  handleNewCustomDataSet,
  handleRemoveCustomDataSet,
  customDataSets,
}) => {
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [color, setColor] = useState("#fff");
  const [dataSetLabel, setDataSetLabel] = useState("");
  const [customDataSet, setCustomDataSet] = useState({});
  const [customDataPoints, setCustomDataPoints] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    let currentCustomDataSet = customDataSet;
    currentCustomDataSet.color = color;
    currentCustomDataSet.label = dataSetLabel;
    currentCustomDataSet.points = customDataPoints;
    setCustomDataSet(currentCustomDataSet);
  }, [color, dataSetLabel, customDataPoints]);

  const handleOpenColorPicker = () => {
    setOpenColorPicker(true);
  };

  const handleCloseColorPicker = () => {
    setOpenColorPicker(false);
  };

  const handleNewDataSet = () => {
    if (dataSetLabel !== "" && customDataPoints !== []) {
      handleNewCustomDataSet(fromStringToFloat(customDataSet));
      setCustomDataSet({});
      setCustomDataPoints([]);
      setColor("#fff");
      setDataSetLabel("");
      handleCloseCustomPanel();
    }
  };

  const handleNewDataPoint = () => {
    let currentDataPoints = customDataPoints;
    currentDataPoints = [...currentDataPoints, { real: 0, imag: 0 }];
    setCustomDataPoints(currentDataPoints);
  };

  const handleRemoveDataPoint = (index) => {
    let currentDataPoints = JSON.parse(JSON.stringify(customDataPoints));
    currentDataPoints.splice(index, 1);
    setCustomDataPoints(currentDataPoints);
  };

  const handleDataPointChange = (value, index, realImag) => {
    let currentDataPoints = JSON.parse(JSON.stringify(customDataPoints));
    currentDataPoints[index][realImag] = value;
    setCustomDataPoints(currentDataPoints);
  };

  const handleExistingItemClick = (index) => {
    setColor(customDataSets[index].color);
    setDataSetLabel(customDataSets[index].label);
    setCustomDataPoints(customDataSets[index].points);
    setSelectedItem(index);
  };

  return (
    <div>
      <Dialog
        open={openCustomPanel}
        onClose={() => handleCloseCustomPanel()}
        aria-labelledby="Custom panel"
      >
        <DialogTitle id="Custom Panel">Customized Datasets</DialogTitle>
        <DialogContent>
          <FormLabel component="legend">Existing DataSets</FormLabel>
          <List component="nav">
            {customDataSets.map((dataSet, index) => {
              return (
                <ListItem id={`${index}listItem`} button id={dataSet.label}>
                  <ListItemIcon id={`${index}listItemIcon`}>
                    {selectedItem === index ? (
                      <AssessmentIcon id={`${index}listItemIconIcon`} />
                    ) : (
                      <AssessmentOutlinedIcon id={`${index}listItemIconIcon`} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    id={`${index}listItemText`}
                    primary={dataSet.label}
                    onClick={() => handleExistingItemClick(index)}
                  />
                  <DeleteOutlinedIcon
                    id={`${index}listItemDeleteIcon`}
                    onClick={() => {
                      handleRemoveCustomDataSet(index);
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
          <FormLabel className="NewDataSetSection" component="legend">
            New DataSet
          </FormLabel>
          <TextField
            margin="dense"
            variant="outlined"
            id="dataSetLabel"
            label="Dataset Label"
            type="text"
            onChange={(event) => {
              setDataSetLabel(event.target.value);
            }}
            value={dataSetLabel}
            fullWidth
          />
          <div>
            <TextField
              margin="dense"
              variant="outlined"
              id="dataSetColor"
              label="Dataset Color"
              type="text"
              value={color}
              onChange={(event) => {
                setColor(event.target.value);
              }}
              fullWidth
            />

            <Button
              variant="outlined"
              onClick={() => handleOpenColorPicker()}
              color="primary"
              fullWidth
            >
              Change color
            </Button>
            <ColorPanel
              openColorPicker={openColorPicker}
              handleCloseColorPicker={handleCloseColorPicker}
              color={color}
              setColor={setColor}
            />
          </div>
          <FormLabel className="DataPointSection" component="legend">
            Data Points
          </FormLabel>
          {customDataPoints.map((dataPoint, index) => {
            return (
              <div className="DataPointSelect">
                <TextField
                  margin="dense"
                  variant="outlined"
                  key={`${index}real`}
                  value={dataPoint.real}
                  label="Real"
                  type="number"
                  fullWidth
                  onChange={(event) =>
                    handleDataPointChange(event.target.value, index, "real")
                  }
                />
                <TextField
                  margin="dense"
                  variant="outlined"
                  key={`${index}imag`}
                  value={dataPoint.imag}
                  label="Imag"
                  type="number"
                  fullWidth
                  onChange={(event) =>
                    handleDataPointChange(event.target.value, index, "imag")
                  }
                />
                <DeleteOutlinedIcon
                  key={`${index}icon`}
                  onClick={() => {
                    handleRemoveDataPoint(index);
                  }}
                />
              </div>
            );
          })}

          <Button
            variant="outlined"
            onClick={() => handleNewDataPoint()}
            color="primary"
            fullWidth
          >
            New data point
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleNewDataSet()} color="secondary">
            Save
          </Button>
          <Button onClick={() => handleCloseCustomPanel()} color="default">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
