import React, { Component } from "react";
var mySmithChart;

class SmithChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    if (typeof mySmithChart !== "undefined") mySmithChart.destroy();

    mySmithChart = new window.Chart(myChartRef, {
      type: "smith",
      options: {
        scale: {
          display: true,
          gridLines: {
            display: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        devicePixelRatio: 10,
        title: {
          display: true,
          position: "top",
          text: "Smith Chart",
          fontSize: 30,
        },
        legend: {
          display: true,
          fullWidth: true,
          position: "right",
          align: "start",
          labels: {
            usePointStyle: false,
            padding: 15,
          },
        },
        elements: {},
        showLines: false,
      },
      data: {
        datasets: this.props.dataSets,
      },
    });
  };

  componentDidMount = () => {
    this.buildChart();
  };
  componentDidUpdate = () => {
    this.buildChart();
  };

  render() {
    return (
      <div className="App-chart">
        <canvas
          ref={this.chartRef}
          //style={{ display: "block", height: "800px", width: "700px" }}
        />
      </div>
    );
  }
}
export default SmithChart;
