import React, { useState, useEffect } from "react";
import "./App.css";
import SmithChart from "./components/SmithChart";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ReplayIcon from "@material-ui/icons/Replay";
import EditIcon from "@material-ui/icons/Edit";
import {
  prepareDataSets,
  generateChartJsDataSet,
  exportConfig,
  importConfig
} from "./utils/utils";
import CheckBoxes from "./components/CheckBoxes";
import Button from "@material-ui/core/Button";
import CustomPanel from "./components/CustomPanel";
var store = require("store");
export default () => {
  const checked = store.get("checked");
  const customDataSetStorage = store.get("customDataSets");
  const [data, setData] = useState([]);
  const [dataSets, setDataSets] = useState([]);
  const [gtChecked, setGtChecked] = useState(
    checked ? checked.gtChecked : true
  );
  const [noGtChecked, setNoGtChecked] = useState(
    checked ? checked.noGtChecked : true
  );
  const [fiveChecked, setFiveChecked] = useState(
    checked ? checked.fiveChecked : true
  );
  const [fivePointChecked, setFivePointChecked] = useState(
    checked ? checked.fivePointChecked : true
  );
  const [pBreakChecked, setPBreakChecked] = useState(
    checked ? checked.pBreakChecked : true
  );
  const [customDataSets, setCustomDatasets] = useState(
    customDataSetStorage ? customDataSetStorage : []
  );
  const [openCustomPanel, setOpenCustomPanel] = useState(false);

  useEffect(() => {
    loadData();
  }, [null]);

  useEffect(() => {
    updateDataSets();
  }, [data]);

  useEffect(() => {
    updateChecked();
    storeDataToLocalStorage();
  }, [
    data,
    gtChecked,
    noGtChecked,
    fiveChecked,
    fivePointChecked,
    pBreakChecked,
    customDataSets
  ]);

  const storeDataToLocalStorage = () => {
    store.set("dataSets", JSON.parse(JSON.stringify(data)));
    store.set("checked", {
      gtChecked,
      noGtChecked,
      fiveChecked,
      fivePointChecked,
      pBreakChecked
    });
    store.set("customDataSets", JSON.parse(JSON.stringify(customDataSets)));
  };

  const resetAll = () => {
    store.clearAll();
    loadData();
    setGtChecked(true);
    setNoGtChecked(true);
    setFiveChecked(true);
    setFivePointChecked(true);
    setPBreakChecked(true);
    loadData();
  };
  const loadData = () => {
    prepareDataSets().then(loadedData => {
      setData(loadedData);
    });
  };
  const updateDataSets = () => {
    let currentdDataSets = [];
    data.forEach(dataItem => {
      if (dataItem.checked) {
        dataItem.chartJsDataSets.forEach(dataSet => {
          let temp = JSON.parse(JSON.stringify(dataSet.chartJsDataSet));
          if (dataSet.checked) {
            currentdDataSets.push(temp);
          }
        });
      }
    });
    customDataSets.forEach(dataSet => {
      let chartJsCustomDataSet = generateChartJsDataSet(
        dataSet,
        dataSet.color,
        0
      );
      currentdDataSets.push(chartJsCustomDataSet);
    });
    setDataSets(currentdDataSets);
  };
  const updateChecked = () => {
    let newData = data;
    newData.forEach(dataItem => {
      dataItem.chartJsDataSets.forEach(dataSet => {
        dataSet.checked = !(
          (dataSet.chartJsDataSet.label.includes("_GT") && !gtChecked) ||
          (dataSet.chartJsDataSet.label.includes("_noGT") && !noGtChecked) ||
          (dataSet.chartJsDataSet.label.includes("5dBm") && !fiveChecked) ||
          (dataSet.chartJsDataSet.label.includes("5.7dBm") &&
            !fivePointChecked) ||
          (dataSet.chartJsDataSet.label.includes("P_Break") && !pBreakChecked)
        );
      });
    });
    setData(newData);
    updateDataSets();
  };

  const handleCheckFrequency = item => {
    var newData = data.map(dataItem =>
      dataItem.frequency === item.dataItem.frequency
        ? { ...dataItem, checked: !item.dataItem.checked }
        : dataItem
    );
    setData(newData);
  };

  const handleNewCustomDataSet = newDataSet => {
    let existing = customDataSets.findIndex(
      obj => obj.label === newDataSet.label
    );
    let currentCustomDataSets = customDataSets;
    if (existing !== -1) {
      currentCustomDataSets[existing] = newDataSet;
    } else {
      currentCustomDataSets.push(newDataSet);
    }
    setCustomDatasets(JSON.parse(JSON.stringify(currentCustomDataSets)));
  };

  const handleRemoveCustomDataSet = index => {
    let currentCustomDataSets = customDataSets;
    currentCustomDataSets.splice(index, 1);
    setCustomDatasets(JSON.parse(JSON.stringify(currentCustomDataSets)));
  };
  const handleCheck = target => {
    switch (target.value) {
      case "_GT":
        setGtChecked(target.checked);
        break;
      case "_noGT":
        setNoGtChecked(target.checked);
        break;
      case "5dBm":
        setFiveChecked(target.checked);
        break;
      case "5.7dBm":
        setFivePointChecked(target.checked);
        break;
      case "P_Break":
        setPBreakChecked(target.checked);
        break;
      default:
        break;
    }
  };

  const handleImportConfig = event => {
    importConfig(event).then(importedConfig => {
      if (importedConfig.customDataSets && importedConfig.dataPoints) {
        prepareDataSets(importedConfig).then(loadedData => {
          setData(loadedData);
          setCustomDatasets(
            JSON.parse(JSON.stringify(importedConfig.customDataSets))
          );
        });
      } else {
        alert("Import file is not valid");
      }
    });
  };

  const handleOpenCustomPanel = () => {
    setOpenCustomPanel(true);
  };

  const handleCloseCustomPanel = () => {
    setOpenCustomPanel(false);
  };
  return (
    <div className="App">
      <CustomPanel
        openCustomPanel={openCustomPanel}
        handleCloseCustomPanel={handleCloseCustomPanel}
        handleNewCustomDataSet={handleNewCustomDataSet}
        handleRemoveCustomDataSet={handleRemoveCustomDataSet}
        customDataSets={customDataSets}
      />
      <div className="Controls">
        <div className="Buttons">
          <div className="ButtonItem">
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleOpenCustomPanel()}
              startIcon={<EditIcon />}
            >
              Customize
            </Button>
          </div>
          <div className="ButtonItem">
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => resetAll()}
              startIcon={<ReplayIcon />}
            >
              Reset
            </Button>
          </div>
          <div className="ButtonItem">
            <Button
              color="default"
              variant="outlined"
              onClick={() => exportConfig()}
              startIcon={<CloudDownloadIcon />}
            >
              Export config
            </Button>
          </div>
          <div className="ButtonItem">
            <input
              accept="application/json"
              id="contained-button-file"
              type="file"
              style={{ display: "none" }}
              onChange={event => {
                handleImportConfig(event);
              }}
            />
            <label htmlFor="contained-button-file">
              <Button
                startIcon={<CloudUploadIcon />}
                variant="outlined"
                color="default"
                component="span"
              >
                Import config
              </Button>
            </label>
          </div>
        </div>
        <CheckBoxes
          data={data}
          handleCheckFrequency={handleCheckFrequency}
          handleCheck={handleCheck}
          gtChecked={gtChecked}
          noGtChecked={noGtChecked}
          fiveChecked={fiveChecked}
          fivePointChecked={fivePointChecked}
          pBreakChecked={pBreakChecked}
        />
      </div>
      <SmithChart dataSets={dataSets} />
    </div>
  );
};
